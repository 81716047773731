export const getProductTypeandLots = (zenId, data) => {
  for (const secType in data) {
    const matchedItem = data[secType].find(item => item.zen_id === zenId);
    if (matchedItem) {
      return {
        securityType:secType,
        lotSize:matchedItem.lot_size
      } 
    }
  }
  return {
    securityType:null,
    lotSize:null
  }; 
};
