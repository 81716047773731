/*eslint-disable*/
import { useGlobalState } from 'components/globalVariable';
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import PnlLineChartComponent from './PnlLineChartComponent';
import { ShimmerThumbnail } from 'react-shimmer-effects';
import { Autocomplete } from '@mui/material';
import {TextField} from '@mui/material';
import { Box, Tabs, Tab } from "@mui/material";
import {
    Button,
    Input,
    Form,
    Label,
    FormGroup,
    Col
    } from 'reactstrap';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { setDateRange, setLineChartData, setTargetSymbol, setIsFetching, setType, setStocks, setZenSecId, setTabIndex } from './PnlLineChartSlice';
import { selectAccount } from 'reducers/BrokerSlice';
import PnlReturnsFilter from 'components/PnlReturnsFilter/PnlReturnsFilterTemplate';
import PnlReturnsFilterTemplate from 'components/PnlReturnsFilter/PnlReturnsFilterTemplate';
import ReturnsLineChart from 'components/ReturnsLineChart/ReturnsLineChart';
import config from 'config';

function PnlLineChartContainer() {
    const {security} = useSelector(state => state.filter);
    const [clientId]= useGlobalState("clientId");
    const [clientName] = useGlobalState('clientName');
    const prevClientNameRef = React.useRef(clientName);    
    const dateRange = useSelector(state => state.pnlLineChart.dateRange);
    const {selectedStrategy, selectedBroker, selectedBrokerName, selectedAccount} =  useSelector(state => state.clientSelection)
    const dispatch = useDispatch();
    const tabIndex = useSelector(state => state.pnlLineChart.tabIndex);
    const isFetching = useSelector(state => state.pnlLineChart.isFetching);
    const isPnlFetching = useSelector(state => state.pnlLineChart.isPnlFetching);
    const stocks = useSelector(state => state.pnlLineChart.stocks);

    const dateConversion = (val) => {
        const dateObj = new Date(val);
        const year = dateObj.getFullYear();
        const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
        const day = ('0' + dateObj.getDate()).slice(-2);
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    }
    
    const handleChange = (event,newValue) => {
      dispatch(setTabIndex(newValue));
  }

    useEffect(()=>{
        if(prevClientNameRef.current !== clientName)
        {
            dispatch(setTargetSymbol(null));
            dispatch(setLineChartData(null));
            dispatch(setType("YTD"));
        }
    },[clientName])
    
    useEffect(() => {
        dispatch(setIsFetching(true));
        const body = {
          "startTimestamp": `${dateConversion(dateRange[0]?.toLocaleDateString() )} 00:00:00`,
          "endTimestamp":  `${dateConversion(dateRange[1]?.toLocaleDateString())} 23:59:59` ,
            "positionCompKeyFilter":{
                "clientId":clientId,
                ...((selectedStrategy === "all" || selectedStrategy === "" || selectedStrategy == null )  ? {  } : { strategyIds : [selectedStrategy]}),
          ...((selectedBroker === "all" || selectedBroker === "" || selectedBroker == null) ? {}: { brokers: [selectedBrokerName] }),  // Only include brokers if broker is not "ALL"
          ...((selectedAccount === "all" || selectedAccount === "" || selectedAccount === null || selectedAccount === undefined )  ? {  } : { accountIds : [selectedAccount]}),
        }
            }
            // ...(strategy !== "" && { strategyIds: [strategy] }),
            
        const fetchData = () => {
        fetch(config.REACT_APP_DEV_URL+'trade/pnl',
        {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        })
        .then(result => result.json())
        .then(rowData => {
            let arr = Array.from(rowData).map((data)=>({ tradingSymbol : data.tradingSymbol, zenSecId :  data.positionCompositeKey.zenSecId}))
            arr = arr.filter((item, index, self) => index === self.findIndex(t => t.tradingSymbol === item.tradingSymbol));
            // let stocks = Array.from(rowData).map((data)=> data.tradingSymbol)
            //this should come from backend with diffrent service point!
            arr =  arr.sort((a, b) => a.tradingSymbol.localeCompare(b.tradingSymbol));
            dispatch(setStocks(arr));
            dispatch(setIsFetching(false));
        })
        .catch((err) => {
            console.log("render error");
        });
        }
        if(dateRange[1] !== null)
            fetchData();
        const intervalId = setInterval(fetchData, 120000);
        //setIntervel cleaning process
        return(()=>{
            clearInterval(intervalId);
        })
      }, [security, dateRange,  selectedAccount ,selectedStrategy, selectedBroker, selectedBrokerName]);
    
    

    return (
      <Box sx={{ width: "100%", typography: "body1" }}>
      <Tabs
        value={tabIndex}
        onChange={handleChange}
        aria-label="Line Chart Tabs"
        variant="fullWidth"
      >
        <Tab label="PNL History" />
        <Tab label="Returns History" />
      </Tabs>
      <PnlReturnsFilterTemplate/>
      <Box sx={{ marginTop: 2 }}>
        {tabIndex === 0 && (
            isFetching && isPnlFetching?
            <ShimmerThumbnail width={500} />
            : stocks?.length ?<PnlLineChartComponent
                dateConversion = {dateConversion}
              /> 
            :<div className='bg-red-200 w-full h-96 flex flex-col items-center justify-center space-y-4'>No data is available for the particular date in this account</div>               
        )}
        {tabIndex === 1 && (
           <ReturnsLineChart/> 
        )}
      </Box>
    </Box>
    )
}
export default PnlLineChartContainer;
