import config from "config";
const getAvailableMargin = (clientId,accountId, dispatch, setAvailableMargin) => {
        fetch(`${config.REACT_APP_DEV_URL}margins?client_id=${clientId}&account_id=${accountId}`, {
                // mode : 'no-cors',
                method : 'GET'
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error("It is error");
                }
                return response.json();
            })
            .then(data => { 
                dispatch(setAvailableMargin(data?.availableCash))
            })
            .catch(error => console.error("Error",error))
    
      
}

export default getAvailableMargin