import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const CustomHeader = (props) => {
  // We don't render the sort order numbers
  const { displayName } = props;
  return (
    <div className="ag-header-cell-label">
      <span className="ag-header-cell-text">{displayName}</span>
    </div>
  );
};

export default CustomHeader;