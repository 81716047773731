/* eslint-disable */
import config from "config"
export const getOptionEODPrice = (zen_id,dispatch, setChartData) => {
  const todayDate = new Date()
  const formattedCurrentMonth = parseInt(todayDate.getMonth()) > 10 ? parseInt(todayDate.getMonth() + 1)  : "0"+ parseInt(todayDate.getMonth() + 1)
  const formattedCurrentDate =  todayDate.getDate() > 10 ? todayDate.getDate() : "0"+todayDate.getDate()
  const currentDate = formattedCurrentDate+"-"+ formattedCurrentMonth +"-" + todayDate.getFullYear()
 
  const pastMonthDate = new Date()
  pastMonthDate.setMonth(pastMonthDate.getMonth() - 2)
  const formattedPastMonth = parseInt(pastMonthDate.getMonth()) >= 10 ? parseInt(pastMonthDate.getMonth()+1) : "0"+parseInt(pastMonthDate.getMonth()+ 1) 
  const formattedPastDate =  pastMonthDate.getDate() > 10 ? pastMonthDate.getDate() : "0"+pastMonthDate.getDate()
  const pastDate = formattedPastDate+"-"+formattedPastMonth+"-"+pastMonthDate.getFullYear()
  
    fetch(config.CORS_URL+'getOptionEODPrice?zen_id='+ zen_id+'&trade_date='+pastDate+','+currentDate, {
            method : 'GET'
          })
          .then(response => {
            if (!response.ok) 
                throw new Error("It is error");
            return response.json();
          })
          .then(data => {dispatch(setChartData(data));})
          .catch(error => console.error("Error",error))
}

export const getFutureEODPrice = (zen_id,dispatch, setChartData) => {
  const todayDate = new Date()
  const formattedCurrentMonth = parseInt(todayDate.getMonth()) > 10 ? parseInt(todayDate.getMonth() + 1)  : "0"+ parseInt(todayDate.getMonth() + 1)
  const formattedCurrentDate =  todayDate.getDate() > 10 ? todayDate.getDate() : "0"+todayDate.getDate()
  const currentDate = formattedCurrentDate+"-"+ formattedCurrentMonth +"-" + todayDate.getFullYear()
 
  const pastMonthDate = new Date()
  pastMonthDate.setMonth(pastMonthDate.getMonth() - 2)
  const formattedPastMonth = parseInt(pastMonthDate.getMonth()) >= 10 ? parseInt(pastMonthDate.getMonth()+1) : "0"+parseInt(pastMonthDate.getMonth()+ 1) 
  const formattedPastDate =  pastMonthDate.getDate() > 10 ? pastMonthDate.getDate() : "0"+pastMonthDate.getDate()
  const pastDate = formattedPastDate+"-"+formattedPastMonth+"-"+pastMonthDate.getFullYear()

  fetch(config.CORS_URL+'getFutureEODPrice?zen_id='+ zen_id+'&trade_date='+pastDate+','+currentDate, {
          method : 'GET'
        })
        .then(response => {
          if (!response.ok) 
              throw new Error("It is error");
          return response.json();
        })
        .then(data => {
          dispatch(setChartData(data))
        })
        .catch(error => console.error("Error",error))
}

export const getEODPrice = (targetNode, popoverIndexLabels, chartData, chartIndexData, setData, isIndex, setYAxisData, isDerivative) => {
 
    const data = [];
    const yAxisData = []
    if(isIndex)
    {
      const filteredTargetData = chartIndexData.filter(data => data.index_name === targetNode);
      const formattedTargetData = filteredTargetData.map(data => [
        Date.UTC(
          parseInt(data.trade_date.split("-")[0]),  // Year
          parseInt(data.trade_date.split("-")[1]) - 1,  // Month (zero-based)
          parseInt(data.trade_date.split("-")[2])  // Day
        ),
        data.close
      ]);
      formattedTargetData.sort((a,b) => a[0]-b[0])
      data.unshift({type : 'line', name: targetNode, data: formattedTargetData, yAxis : 0 });
      yAxisData.push({title: {
        text: targetNode,
        labels: {
          format: '{value}' // Format for NIFTY 50
        }
      },
      opposite: false})
    }
    else 
    {
      for (const index in popoverIndexLabels) {
            const filteredData = chartIndexData[index].data;
            const formattedData = filteredData.map(data => [
              Date.UTC(
                parseInt(data.trade_date.split("-")[0]),  // Year
                parseInt(data.trade_date.split("-")[1]) - 1,  // Month (zero-based)
                parseInt(data.trade_date.split("-")[2])  // Day
              ),
              data.close
            ]);
            formattedData.sort((a,b) => a[0]-b[0])
            data.push({ type : 'line',name: chartIndexData[index].indexName, data: formattedData , yAxis : (+index) + 1 });

            
            yAxisData.push({title: {
              text: chartIndexData[index].indexName,
              labels: {
                format: '{value}' // Format for NIFTY 50
              }
            },
            opposite: true})
          
          }
      if(isDerivative)
      {
          // Filter and format data for targetNode
          const formattedTargetData = chartData.map(data => [
            Date.UTC(
              parseInt(data.fh_timestamp.split("-")[0]),  // Year
              parseInt(data.fh_timestamp.split("-")[1]) - 1,  // Month (zero-based)
              parseInt(data.fh_timestamp.split("-")[2])  // Day
            ),
            data.fh_closing_price
          ]);
          formattedTargetData.sort((a,b) => a[0]-b[0])
      
          data.unshift({type : 'line', name: targetNode, data: formattedTargetData , yAxis : 0 });

          yAxisData.unshift({title: {
            text: targetNode,
            labels: {
              format: '{value}' // Format for NIFTY 50
            }
          },
          opposite: false})
      }
      else{
        const formattedTargetData = chartData.map(data => [
          Date.UTC(
            parseInt(data.trade_date.split("-")[0]),  // Year
            parseInt(data.trade_date.split("-")[1]) - 1,  // Month (zero-based)
            parseInt(data.trade_date.split("-")[2])  // Day
          ),
          data.close
        ]);
        formattedTargetData.sort((a,b) => a[0]-b[0])
    
        data.unshift({type : 'line', name: targetNode, data: formattedTargetData , yAxis : 0 });

        yAxisData.unshift({title: {
          text: targetNode,
          labels: {
            format: '{value}' // Format for NIFTY 50
          }
        },
        opposite: false})
      }   
    }
   
    
    setData([...data]);
    setYAxisData([...yAxisData])
};