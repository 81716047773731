/*eslint-disable*/
import { useDispatch, useSelector } from 'react-redux';
import './OptionChain.css'
import { setCallType, setShowOrderForm, setTradingSymbol} from './OptionChainSlice'
import { setType } from 'components/OrderForm/Field/orderFormSlice';
import { useGlobalState } from 'components/globalVariable';
import { setProduct } from 'components/OrderForm/Field/orderFormSlice';
const ButtonRenderer = ({value, tradingSymbol}) => {
  const dispatch = useDispatch();
  const [clientId] = useGlobalState("clientId");
  return (
    <div>
        <span className='value-column'>{isNaN(value) ? value : Number(value).toLocaleString('en-IN', {
        maximumFractionDigits: 4,
        minimumFractionDigits: 4})}</span>
        <div className="button-container">
            <button 
              className="buy-button" 
              onClick={
                () => {
                  if(!clientId){
                    alert("Kindly select the trade profile!");
                    return;
                  }
                     dispatch(setShowOrderForm(true)); 
                     dispatch(setTradingSymbol(tradingSymbol))
                     dispatch(setCallType("BUY"));
                     dispatch(setType("OPT"));
                    }
                  }
            >B</button>
            <button 
              className="sell-button" 
              onClick={
                () => {
                  if(!clientId){
                    alert("Kindly select the trade profile!");
                    return;
                  }
                  dispatch(setShowOrderForm(true));
                  dispatch(setTradingSymbol(tradingSymbol))
                  dispatch(setCallType("SELL"));
                  dispatch(setType("OPT"));

                  }}
            >S</button>
        </div>
    </div>
    
  );
};

export default ButtonRenderer;
