/* eslint-disable */
/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

import { combineReducers } from 'redux';
import FilterReducer from '../reducers/FilterSlice';
import BrokerReducer from '../reducers/BrokerSlice';
import clientSelectionReducer from '../reducers/clientSelectionSlice';
import PositionActionReducer from 'components/PositionsGrid/container/PositionActionSlice';
import GraphTemplateReducer from '../components/GraphTemplate/container/GraphTemplateSlice';
import MainTemplateReducer from '../components/CytoscapeGraph/container/MainTemplateSlice';
import ChartTemplateReducer from '../components/Chart/container/ChartTemplateSlice';
import PnlTableOptionReducer from 'components/PnlGrid/container/PnlTableOptionSlice';
import PositionTableOptionReducer from 'components/PositionsGrid/container/PositionTableOptionSlice';
import TradesTableOptionReducer from 'components/TradesGrid/container/TradesTableOptionSlice';
import DerivativeGraphReducer from 'components/DerivativeGraph/container/DerivativeGraphSlice';
import MarginTemplateReducer from 'components/Margin/container/MarginTemplateSlice';
import OptionChainSliceReducer from 'components/OptionChain/OptionChainSlice';
import BetaSlice from 'components/Beta/BetaSlice';
import orderFormSlice from 'components/OrderForm/Field/orderFormSlice';
import PnlLineChartSlice from 'components/PnlLineChart/PnlLineChartSlice';
import DemoNavbarSlice from 'components/Navbars/DemoNavbarSlice';
import ReturnsLineChartSlice from 'components/ReturnsLineChart/ReturnsLineChartSlice';
import ExternalTradesSlice from 'components/ExternalTrades/ExternalTradesSlice';
const rootReducer = combineReducers({
  clientSelection: clientSelectionReducer,
  broker : BrokerReducer,
  filter : FilterReducer,
  graphTemplate : GraphTemplateReducer,
  mainTemplate : MainTemplateReducer,
  externalTrades : ExternalTradesSlice,
  returnsLineChart : ReturnsLineChartSlice,
  navbar : DemoNavbarSlice,
  orderForm : orderFormSlice,
  portfolioBeta: BetaSlice,
  derivativeGraphTemplate : DerivativeGraphReducer,
  chartTemplate : ChartTemplateReducer,
  pnlTableOption : PnlTableOptionReducer,
  positionTableOption : PositionTableOptionReducer,
  tradesTableOption : TradesTableOptionReducer,
  marginTemplate : MarginTemplateReducer,
  optionChainTemplate : OptionChainSliceReducer,
  positionActions : PositionActionReducer,
  pnlLineChart : PnlLineChartSlice
});

export default rootReducer;
