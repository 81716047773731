/*eslint-disable*/

import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Validity from "../Validity/Validity";
import {Autocomplete, TextField, Paper, List, ListItem, ListItemText,Popper } from "@mui/material";
import Button from '@mui/material/Button';
import { MdCancel } from "react-icons/md";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { setTradingSymbol, setShowOrderForm } from "components/OptionChain/OptionChainSlice";
import { setOrderStrategyId } from "reducers/FilterSlice";
import { validatePrice, validateQuantity } from "./orderFormValidation";
import { setAutocompleteValue, setLotSize, setLots, setQtyFocus, 
  setLotsFocus, setExchange, setOrderType, setOrderValue, setIsUpdateForm, setIsDeleteBtn, setType, setProduct, setEnableTriggerPoints } from "./orderFormSlice";

import { selectBroker, selectAccount, selectStrategy } from 'reducers/BrokerSlice.js'; // Adjust the path
import { setAccounts } from "reducers/BrokerSlice";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



export default function OrderFormTemplate({
      updateData,
      setUpdateData,
      setFormData,
      trade,
      handleDialogClose,
      handleFormSubmit,
      handleSubmit,
      register,
      errors,
      setValue,
      OrderType,
      orderValue,
      handleChange,
      Prices,
      broker,
      zen,
      hideForm,
      filterOptions,
      handleTypeChange,
      handleClose,
      fData,
      watch,
      TriggerPoints
    }) {

  const orderType = useSelector(state => state.orderForm.orderType);
  const isUpdateForm = useSelector(state => state.orderForm.isUpdateForm);
  const securities = useSelector(state => state.orderForm.securities);  
  const lotSize = useSelector(state => state.orderForm.lotSize);
  const lots = useSelector(state => state.orderForm.lots);
  const dispatch = useDispatch();
  const autoSquareTradingSymbol = useSelector(state => state.positionActions.tradingSymbol);
  const type = useSelector(state => state.orderForm.type);
  const optionChainTradingSymbol = useSelector(state => state.optionChainTemplate.tradingSymbol);
  const autocompleteValue = useSelector(state => state.orderForm.autocompleteValue);
  const open = useSelector(state => state.orderForm.open);
  const pendingData = useSelector(state => state.orderForm.pendingData);
  const enableTriggerPoints = useSelector(state => state.orderForm.enableTriggerPoints);

  //BrokerSlice 
  const brokers = useSelector(state => state.broker.brokers);
  const accounts = useSelector(state => state.broker.accounts);
  const strategies = useSelector(state => state.broker.strategies);

  const selectedBroker = useSelector(state => state.clientSelection.selectedBrokerName);
  const selectedAccount = useSelector(state => state.clientSelection.selectedAccount);
  const selectedStrategy = useSelector(state => state.clientSelection.selectedStrategy);

  const clientInfo = useSelector(state => state.clientSelection.clientInfo);

  const brokerFilter = useSelector(state => state.filter.broker);
  const account = useSelector(state => state.filter.account);
  const strategy = useSelector(state => state.filter.strategy);

  const selectedFromBroker = watch("broker")
  const selectedFromAccount = watch("accountId")
  const selectedFormStrategy = watch("strategyId")
  const product = useSelector(state => state.orderForm.product);

  const handleBrokerChange = (brokerName) => {
    setValue("broker",brokerName)
  };

  //To set initaial broker value from Nav bar
  useEffect(()=>{
    if(!autoSquareTradingSymbol){
      setValue("broker",selectedBroker)
    }
    
  },[selectedBroker])

  const getAccounsByBrokerName = (clientInfo,name) =>{
      // Find the broker info by name
      const broker = clientInfo.broker_infos.find(broker => broker.broker_name === name);
          
      // Check if broker exists and has account info
      if (broker && broker.account_infos) {
          // Map to get only account IDs and names
          return broker.account_infos.map(account => ({
              account_id: account.account_id,
              account_name: account.account_name
          }));
      }
  }

  const [availabeAccounts,setAvailabeAccounts] = useState([])
  useEffect(()=>{
    let accounts = getAccounsByBrokerName(clientInfo,selectedFromBroker)
    if(accounts?.length===1){
      setValue("accountId",accounts[0].account_id)
    }
    setAvailabeAccounts(accounts)

  },[selectedFromBroker])

  const handleAccountChange = (accountId) => {
    setValue("accountId",accountId)
  };

  //To set initial account value from nav bar
  useEffect(()=>{
    if(selectedAccount!=='all' && selectedAccount!==""){
      setValue("accountId",selectedAccount)
    }
  },[selectedAccount])

  function getStrategiesByBrokerAndAccountId(data, brokerName, accountId) {
    // Find the broker info by name
    const broker = data.broker_infos.find(broker => broker.broker_name === brokerName);
    
    // Check if broker exists and has account info
    if (broker && broker.account_infos) {
        // Find the account by account name
        const account = broker.account_infos.find(account => account.account_id === accountId);
        
        // Check if account exists and has strategies
        if (account && account.strategies) {
            // Map to get only strategy IDs and names
            return account.strategies.map(strategy => ({
                strategy_id: strategy.strategy_id,
                strategy_name: strategy.strategy_name
            }));
        }
    }
    
    // Return an empty list if no broker, account, or strategies are found
    return [];
  }

  const [availableStrategies,setAvailableStrategies] = useState([])
  useEffect(()=>{
    let strategies = getStrategiesByBrokerAndAccountId(clientInfo,selectedFromBroker,selectedFromAccount)
    setAvailableStrategies(strategies)

    if(strategies?.length===1){
      setValue("strategyId",strategies[0].strategy_id)
      
    }

  },[selectedFromBroker,selectedFromAccount])

  //To set initial strategy value from nav bar
  useEffect(()=>{
    if(selectedStrategy!=='all' && selectedStrategy!==""){
      setValue("strategyId",selectedStrategy)
      
    }
  },[selectedStrategy])

  const handleStrategyChange = (strategyId) => {
    dispatch(selectStrategy(strategyId)); // Set selected strategy
    setValue("strategyId",strategyId)
    
  };

  
  return (

    <div className="order-form bg-blue-50 border-[1px] border-black/60">
          <div className={`p-3 mt-0 text-white flex flex-wrap font-semibold cursor-move ${trade === "BUY" ? "bg-blue-600" : "bg-slate-600"}`}>
            <span className="flex-shrink-0 w-[460px]">{trade}  {optionChainTradingSymbol? optionChainTradingSymbol: autoSquareTradingSymbol?autoSquareTradingSymbol:zen ? zen : "" }</span>
            <button
              onClick={() => {
                dispatch(setType("EQ"));
                handleClose(hideForm);
                dispatch(setEnableTriggerPoints(false));

              }}
              className={trade === "BUY" ? "flex-wrap bg-blue-600" : "flex-wrap bg-slate-600"}
            >
              <MdCancel className="text-white hover:scale-150" />
            </button>
          </div>

        <Dialog
              className="z-[100000]"
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => handleDialogClose(false, pendingData, dispatch)}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle>{"Are you sure to proceed?"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                Quantity is greater than 1000
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => handleDialogClose(false,undefined, dispatch)}>Cancel</Button>
                <Button onClick={() => handleDialogClose(true,undefined, orderValue, dispatch)}>Okay</Button>
              </DialogActions>
        </Dialog>
  <form className="form cursor-auto" onSubmit={handleSubmit(handleFormSubmit, (errors) => console.error(errors))}>
        <div className="form-div">
          {zen || autoSquareTradingSymbol || optionChainTradingSymbol || isUpdateForm ?
          null:<Box>
            <Tabs
              onChange={(event, value) => handleTypeChange(event,value)}
              value={type}
              aria-label="Tabs where each tab needs to be selected manually"
            >
              <Tab label="EQ" value={"EQ"}/>
              <Tab label="OPT" value={"OPT"}/>
              <Tab label="FUT" value={"FUT"}/>
            </Tabs>
          </Box>}
          <div className="tab">
            <div>
              <label className="form-label" style={{color:"black"}}>Security</label>
                 <Autocomplete
                  disablePortal
                  key={type}
                  noOptionsText={securities.length?"No results found":"Loading..."}
                  className="auto-complete"
                  style={{ width: 250 }}
                  id="combo-box-demo"
                  inputValue={autocompleteValue}
                  onInputChange={(event, newInputValue,option) => {
                    dispatch(setAutocompleteValue(newInputValue));
                  }}
                  autoHighlight = {true}
                  // onKeyDown={(event) => {
                  //   if (event.key === "Enter") {
                  //     event.preventDefault(); // Prevents default form behavior
                      
                  //     const listbox = document.querySelector('.MuiAutocomplete-listbox');
                  //     console.log("List box : ", listbox);
                  //     const focusedOption = listbox?.querySelector('.MuiAutocomplete-option[aria-selected="true"]');
                  //     console.log("focused option : ", focusedOption);
                
                  //     if (focusedOption) {
                  //       focusedOption.click(); // Programmatically trigger selection
                  //     }
                  //   }
                  // }}
                  isOptionEqualToValue={(option, value) => option?.title === value?.title}
                  onChange={(e,value)=>{
                    if(value?.lotSize){
                      dispatch(setLotSize(value.lotSize));
                      setValue("quantity",lots*value?.lotSize)
                      setValue("zenId",value?.zenId)
                    }else{
                      dispatch(setLotSize('NA'));
                    }
                  }}
                  disabled={updateData !== undefined}
                  defaultValue={optionChainTradingSymbol?optionChainTradingSymbol: autoSquareTradingSymbol?autoSquareTradingSymbol:zen ? zen : updateData !== undefined  ? updateData?.tradingSymbol : null}
                  options={securities}
                  filterOptions={filterOptions}
                  groupBy={(option) => option.type}
                  getOptionLabel={(option) => option.title ||zen || autoSquareTradingSymbol || optionChainTradingSymbol || updateData?.tradingSymbol}
                  sx={{
                      width: 230,
                      "& .MuiInputBase-root": {
                          fontSize: ".9rem",
                          height: "40px",
                          fontWeight: "500",
                          paddingTop:"0px",
                          bgcolor: "white"
                      },
                    "& .MuiAutocomplete-option.Mui-focused": {
                      bgcolor: "rgba(0,0,0,0.3)",
                      
                    }
                  }}
                  renderInput={(params) => (
                      <TextField {...params} required {...register("tradingSymbol")} />
                  )}
                  renderOption={(props, item) =>{
                    return(
                      <span {...props} key={item.title+item.zenId+item.index}>
                        {item.title}
                      </span>
                    )
                  }
                }
              />
              <span className="form-error">{errors.tradingSymbol?.message}</span>
            </div>

            
            <div>
              <label className="form-label" style={{color:"black"}}>Order</label>
              <Autocomplete
                className="auto-complete"
                disablePortal
                id="combo-box-demo"
                options={OrderType}
                onChange={(e, selectedOption) =>handleChange(selectedOption)}
                // disabled={updateData !== undefined}
                value = {updateData !== undefined ? updateData?.orderType : orderType}
                PaperComponent={({ children }) => (
                  <Paper sx={{
                    "& .MuiInputBase-root":{padding:"0px 0px 0px 5px"},
                    "& .MuiAutocomplete-listbox": {
                      display: 'flex',
                      flexDirection: 'column',
                      "& .MuiAutocomplete-option": {
                        width: '230px',
                        fontSize: '0.7rem',
                      },
                    },
                    "& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused": {
                      bgcolor: "rgba(0,0,0,0.3)",
                    },
                    
                  }}>
                    {children}
                  </Paper>
                )}
                sx={{
                  width: 230,
                  "& .MuiInputBase-root": {
                    fontSize: ".8rem",
                    height: "40px",
                    fontWeight: "500",
                    bgcolor: "white"
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    style={{ fontSize: "2rem" }}
                    {...params}
                    required
                    {...register("orderType")}
                  />
                )}
              />
            </div>
          </div>

          <div className="tab">

          {!enableTriggerPoints && Prices.map((el) => (
            <Form.Group className="mb-1"  key={el}>
              <Form.Label className="form-label" style={{color:"black"}}>{(el[0].toUpperCase()+el.slice(1,)).replace(/([a-z])([A-Z])/g, '$1 $2')}</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Price"
                className="input-size"
                value={orderValue.includes(el) ? '' : undefined}
                disabled={orderValue.includes(el)}
                {...register(el, {
                  required:orderValue.includes(el)?false:"Enter the price",
                  validate:  {
                    checkValue: (val) =>
                      !(val < 0 || val > 20000) || "Enter price (0-20,000)",
                  },
                  pattern: {
                    value: /^-?\d+(\.\d+)?$/,
                    message: "Price should not include characters",
                  },

                })}
              />
              {!orderValue.includes(el) && errors[el] && (
                <span className="form-error">{errors[el].message}</span>
              )}
            </Form.Group>
          ))}
          </div>
          <div className="tab">
          {enableTriggerPoints && TriggerPoints.map((el) => (
            <Form.Group className="mb-1"  key={el}>
              <Form.Label className="form-label" style={{color:"black"}}>{(el[0].toUpperCase()+el.slice(1,)).replace(/([a-z])([A-Z])/g, '$1 $2')}</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Price"
                className="input-size"
                value={orderValue.includes(el) ? '' : undefined}
                disabled={orderValue.includes(el)}
                {...register(el, {
                  required:orderValue.includes(el)?false:"Enter the price",
                  validate:  {
                    checkValue: (val) =>
                      !(val < 0 || val > 20000) || "Enter price (0-20,000)",
                  },
                  pattern: {
                    value: /^-?\d+(\.\d+)?$/,
                    message: "Price should not include characters",
                  },

                })}
              />
              {!orderValue.includes(el) && errors[el] && (
                <span className="form-error">{errors[el].message}</span>
              )}
            </Form.Group>
          ))}
          </div>
          <div className="tab">
          <Form.Group className="mb-1">
              <Form.Label style={{color:"black"}}>Lots :</Form.Label>
              
              <Form.Control
                disabled={type!=="EQ"?false:true}
                type="number"
                placeholder="Lots Size"
                value={lots}
                onChange={(e)=>{
                    dispatch(setLots(e.target.value));
                }}
                defaultValue={null}
                className="input-size"
                onFocus={()=> dispatch(setLotsFocus(true))}
                onBlur={()=> dispatch(setLotsFocus(false))}
              />
              
              </Form.Group>

            <Form.Group className="mb-1">
              <Form.Label style={{color:"black"}}>Quantity : </Form.Label>
              <Form.Control
                disabled={type==="EQ"?false:true}
                type="number"
                placeholder="Quantity"
                defaultValue={lotSize}
                className="input-size"
                {...register("quantity", {
                  valueAsNumber: true,
                  required: "Enter the quantity",
                  validate: validateQuantity
                })}
                onFocus={()=> dispatch(setQtyFocus(true)) }
                onBlur={()=> dispatch(setQtyFocus(false))}
                
              />
              <span className="form-error"> {errors.quantity?.message}</span>
            </Form.Group>

           

          </div>

          <div className="tab">
          <Form.Group className="mb-1">
                <Form.Label style={{color:"black"}}>Product</Form.Label>
                <Form.Select className="input-size border-[1px] border-slate-400" 
                  // disabled={updateData !== undefined} 
                  // defaultValue={type === "EQ" ? "CNC" : "NRML"} 
                 onChange={(e) => {
                  setValue("product", e.target.value)
                 }}
                  {...register("product")}>
                  {
                      type==="EQ" ? (
                        <>
                          <option value="CNC">CNC</option>
                          <option value="MIS">MIS</option>
                        </>                        
                      )
                      :
                      (
                        <>
                          <option value="NRML">NRML</option>
                          <option value="MIS">MIS</option>
                        </>  
                      )
                    
                  }
                  
                </Form.Select>
            </Form.Group>


              <Form.Group className="mb-1">
                <Form.Label style={{color:"black"}}>Broker</Form.Label>
                <Form.Select className="input-size border-[1px] border-slate-400" 
                  // disabled={updateData !== undefined} 
                  {...register("broker",{
                    validate:(val)=>{
                    if(val!=="")return true
                    else return false
                  },
                  required: "Select broker",
                
                })} 
                disabled={updateData !== undefined}
                  onChange={(e)=>{handleBrokerChange(e.target.value)}}
                  >
                    <option value="" disabled>Select Broker</option>
                    {
                      brokers?.length > 0 ? (
                        brokers?.map(broker => (
                          <option key={broker.broker_id} value={broker.broker_name} >
                            {broker.broker_name}
                          </option>
                        ))
                      ) : (
                        <option disabled>
                          {brokers?.length === 0 ? "No broker allocated" : "Loading..."}
                        </option>
                      )
                    }
                   <span className="form-error"> {errors.broker?.message}</span>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-1">
                <Form.Label style={{ marginBottom: '2px', color: "black" }}>Exchange </Form.Label>
                <Form.Select
                  className="input-size border-[1px] border-slate-400"
                  // disabled={updateData !== undefined}
                  {...register("exchange")}
                 
                  onChange={(e) => {
                    dispatch(setExchange(e.target.value)); 
                    
                  }} // Handle change
                >
                  {
                      type==="EQ" ? (
                        <option value="NSE">NSE</option>
                      )
                      :
                      (
                        <option value="NFO">NFO</option>
                      )
                    
                  }
                </Form.Select>
          </Form.Group>
 
          </div>
          
          <div className="tab">

          <Form.Group className="mb-1">
            <Form.Label style={{ color: "black", marginBottom: "2px" }}>Account Id</Form.Label>
            <Form.Select
               {...register("accountId",{
                validate:(val)=>{
                if(val!=="")return true
                else return false
              },
              required: "Select Account",
            
            })} 
              className="input-size border-[1px] border-slate-400"
              style={{ width: 230 }}
              onChange={e => handleAccountChange(e.target.value)}
              disabled={updateData !== undefined}
              value={selectedFromAccount}
            >
              <option value="" >Select an account</option>
              {selectedFromBroker ? (
                availabeAccounts?.length > 0 ? (
                  availabeAccounts.map(account => (
                    <option key={account.account_id} value={account.account_id}>
                      {account.account_name}
                    </option>
                  ))
                ) : (
                  <option disabled>No accounts allocated</option>
                )
              ) : (
                <option disabled>Please select broker</option>
              )}
            </Form.Select>
          </Form.Group>


          <Form.Group className="mb-1">
                <Form.Label style={{color:"black", marginBottom: "2px"}}>Strategy</Form.Label>
                <Form.Select className="input-size border-[1px] border-slate-400" 
                  disabled={updateData !== undefined}
                  style={{ width: 230 }} 
                  {...register("strategyId")} 
                  onChange={e => handleStrategyChange(e.target.value)}
                  value={selectedFormStrategy}
                  >
                  <option value="" disabled>Select a strategy</option>
                  {availableStrategies?.length > 0 ?
                    availableStrategies.map(strategy => (
                      <option key={strategy.strategy_id} value={strategy.strategy_id}>
                        {strategy.strategy_name}
                      </option>
                    ))
                    :
                    <option disabled>
                          {availableStrategies?.length === 0 ? "No strategies allocated" : "Loading..."}
                    </option>
                  }
                </Form.Select>
          </Form.Group>
            </div>
            <div className="tab" style={{width: "100px"}}>
          </div>
        </div>


        <Validity
          formData={updateData}
          setFormData={setFormData}
          register={register}
        />
      {isUpdateForm && <button className={"cancel-btn red"} type="submit"
        onClick={() => {
          dispatch(setIsDeleteBtn(true));
      }}
      >Delete</button>}
       <button 
        className={trade == "BUY" ? "submit-btn blue" : "submit-btn black"}
        type="submit"
        >{!zen ? (isUpdateForm? "Update" : trade) : "Place Order"}
        </button> 
      </form>
    </div>
  );
}
