/* eslint-disable */

import { useSelector } from "react-redux";
import {Route, Routes, Navigate } from "react-router-dom";
import AdminLayout from './layouts/Admin2.js';
import DerivativeGraphTempalte from "components/DerivativeGraph/container/DerivativeGraphTemplate"
import HomePage from "components/HomePage/HomePage.jsx";
import ProtectedRoute from "ProtectedRoute.js";

const App = () => {
    const element = useSelector(state => state.graphTemplate.elementCopy);
    return(
        <Routes>
          <Route path="/home" element={<HomePage/>} />
          <Route path="/" element={<Navigate to="/home" replace />} />
          <Route path="/default/*" 
          element={
              <ProtectedRoute>
                <AdminLayout />
              </ProtectedRoute>
            } 
          />
          <Route path="/default/derivative/:equityName" 
            element = {
              <ProtectedRoute>
                <DerivativeGraphTempalte elements = {element}/>
              </ProtectedRoute>
            }/>
        </Routes> 
    )
}

export default App