
    const config = {
  "DB_HOST": "192.168.20.13",
  "DB_PORT": "5432",
  "DB_USER": "postgres",
  "DB_PASSWORD": "root",
  "DB_NAME": "equity_graph",
  "DB_CONNECT": "https://192.168.20.10:3003/",
  "CORS_URL": "https://192.168.20.10:9000/",
  "REACT_APP_DEV_URL": "https://api.phoenix.zentropylabs.com/",
  "GRAPH_NAME": "'equity_graph'",
  "CLIENT_ID": "t7lfWdDyRFcoSwfTSFvEG6kE49ad8dGL",
  "UI_URL": "https://phoenix.zentropylabs.com/"
};
    export default config;
    