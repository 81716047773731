/*eslint-disable*/
import { useDispatch, useSelector } from "react-redux";
import { setIsReturnsFetching, setReturnsData } from "./ReturnsLineChartSlice";
import ReturnsLineChartTemplate from "./ReturnsLineChartTemplate";
import { useGlobalState } from "components/globalVariable";
import { ShimmerThumbnail } from 'react-shimmer-effects';
import config from "config";

const ReturnsLineChart = () => {
    
    const {selectedStrategy, selectedBroker, selectedBrokerName, selectedAccount} =  useSelector(state => state.clientSelection)
    const zenSecId = useSelector(state => state.pnlLineChart.zenSecId);
    const dispatch = useDispatch();
    const dateRange = useSelector(state => state.pnlLineChart.dateRange);
    const [clientId]= useGlobalState("clientId");
    const targetSymbol = useSelector(state => state.pnlLineChart.targetSymbol);
    const isReturnsFetching = useSelector(state => state.returnsLineChart.isReturnsFetching);
    const isFetching = useSelector(state => state.pnlLineChart.isFetching);
    const stocks = useSelector(state => state.pnlLineChart.stocks);
    const dateConversion = (val) => {
        const dateObj = new Date(val);
        const year = dateObj.getFullYear();
        const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
        const day = ('0' + dateObj.getDate()).slice(-2);
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    }

    // useEffect(() => {
    //     const fetchReturnsData = () => {
    //         dispatch(setIsReturnsFetching(true));
    //         const body = {
    //           ...(dateRange[0].toLocaleDateString() !== new Date().toLocaleDateString() ? {
    //             startTimestamp: `${dateConversion(dateRange[0]?.toLocaleDateString() )} 00:00:00`,
    //             endTimestamp:  `${dateConversion(dateRange[1]?.toLocaleDateString())} 23:59:59` ,
    //         } : {}),
             
    //           positionCompKeyFilter: {
    //               clientId: clientId,
    //               ...((zenSecId === null|| zenSecId?.length === 0)  ? {  } : { zenSecIds: [zenSecId]}),
                  
    //               ...((selectedAccount === "all" || selectedAccount === "" || selectedAccount === null || selectedAccount === undefined )  ? {  } : { accountIds : [selectedAccount]}),
    //               // Conditionally include strategyIds only if strategy is not empty
    //               ...((selectedStrategy === "all" || selectedStrategy === "" || selectedStrategy === null || selectedStrategy === undefined )  ? {  } : { strategyIds : [selectedStrategy]}),
    //               ...((selectedBroker === "all" || selectedBroker === "" || selectedBroker === null || selectedBroker === undefined) ? {}: { brokers: [selectedBrokerName] })  // Only include brokers if broker is not "ALL"
    //           }
    //         };
    //         fetch(config.REACT_APP_DEV_URL+'trade/position',
    //         {
    //             method: 'POST',
    //             headers: {
    //               Accept: 'application/json',
    //               'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify(body),
    //         })
    //         .then(result => result.json())
    //         .then(rowData => {
    //             if(targetSymbol){
    //               dispatch(setReturnsData(rowData?.map(data => ({date : data?.date, returns : (data?.unrealizedPnl/ data?.openCost) * 100 }))));
    //             }
    //             else{
    //               // const aggregatedData = rowData.reduce((acc, item) => {
    //               //   const { date, openCost, unrealizedPnl } = item;
    //               //   if (!acc[date]) {
    //               //     acc[date] = { date, returns : 0};
    //               //   }
    //               //   acc[date].returns += ((unrealizedPnl/openCost) * 100);
    //               //   return acc;
    //               // }, {});
    //               // dispatch(setReturnsData(Object.values(aggregatedData)));
    //               dispatch(setReturnsData([]));
    //             }
    //             dispatch(setIsReturnsFetching(false));
    //             // setTimeout(()=>{ dispatch(setIsReturnsFetching(false));},600)
    //         })
    //         .catch((err) => {
    //             console.log("render error",err);
    //         });
    //       }
    //     if(dateRange[1] !== null)
    //         fetchReturnsData();
    //   }, [zenSecId, dateRange,selectedAccount, selectedStrategy, selectedBroker]);

      return (
        isReturnsFetching && isFetching ? 
        <ShimmerThumbnail width={500} /> :
        stocks?.length ?
        <ReturnsLineChartTemplate
            targetSymbol = {targetSymbol}
        />
        :<div className='bg-red-200 w-full h-96 flex flex-col items-center justify-center space-y-4'>No data is available for the particular date in this account</div>               
        )
}

export default ReturnsLineChart;