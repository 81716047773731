/*eslint-disable*/
import zIndex from '@mui/material/styles/zIndex';
import { createSlice } from '@reduxjs/toolkit';
import CustomHeader from './CustomHeader';
import CustomHeader2 from './CustomHeader2';
import UnrealizedPnlCellRenderer from 'components/PositionsGrid/container/UnrealizedPnlCellRenderer';
import { strategyLists } from '../presentation/PnlGrid';
import '../presentation/PnlTable.css'

const realizedPnlGetter = (params) => {
    const formattedValue = Math.round(Number(params.value)).toLocaleString('en-IN', {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0
    });
    return formattedValue;
};
const unRealizedPnlGetter = (params) => {
    const formattedValue = Math.round(Number(params.value)).toLocaleString('en-IN', {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0
    });
    return formattedValue;
};




const initialState = {
    filterText : "",
    columnOptions : [
        {
            headerName: "  ",  
            headerClass: 'align-left', 
            cellStyle: { textAlign: "left" },
            width: 10,
            sort: null
        },
        {
            headerName: "Date", field: 'date',
            headerClass: 'align-left',
            cellStyle: { textAlign: "left" },
            width:110,
            sort: null 
        },
        {
            headerName: "Trading Symbol", field: 'tradingSymbol',
            filter: true,
            filter: "agTextColumnFilter",
            headerClass: 'align-left',
            cellClass: 'align-left',
            filter: "agSetColumnFilter",
            cellStyle:{textAlign:"left",},
            width: 200,
            sort: null   
        },
        {
            headerName: "Broker", field: 'positionCompositeKey.broker', 
            headerClass: 'align-left',
            cellStyle: { textAlign: "left" },
            width:120,
            sort: null    
        }, 
        {
            headerName: "Strategy Name", field: "strategyLists", 
            headerClass: 'align-left',
            cellStyle: { textAlign: "left" },
            width:192,
            sort: null,
            valueGetter: (params) => {
                const strategyId = params?.data?.positionCompositeKey?.strategyId; 
                // Return the corresponding strategy if it exists
                return strategyId && strategyLists ? strategyLists.filter(str => str.strategy_id === strategyId )[0]?.strategy_name : ''            }    
        },
        {
            headerName: "Realized LTD",field: 'realizedLTDPnl',
            //headerComponent: CustomHeader,
            valueFormatter:realizedPnlGetter,
            //headerComponentParams: { type: 'realized', headerName: "Realized Pnl"},
            headerClass: "custom-header-padding",
            cellClass: 'align-right',
            width:100,
            sort: null,
            cellRenderer: UnrealizedPnlCellRenderer,
            cellRendererParams: {
                valueFormatter: realizedPnlGetter
            },
        },
        {
            headerName: "Unrealized LTD",field: 'unRealizedLTDPnl',
            //headerComponent: CustomHeader,
            valueFormatter:unRealizedPnlGetter,
            //headerComponentParams: { type: 'unRealized', headerName: "Unrealized Pnl" },
            headerClass: "custom-header-padding",
            cellClass: 'align-right',
            width:120,
            sort: null,
            cellRenderer: UnrealizedPnlCellRenderer,
            cellRendererParams: {
                valueFormatter: unRealizedPnlGetter
            }
        },
        {
            headerName: "Dividend LTD", field: "dividend", 
            headerClass: 'align-left',
            cellClass: 'align-right',
            width:115,
            sort: null,
            cellRenderer: UnrealizedPnlCellRenderer,
            cellRendererParams: {
                valueFormatter: unRealizedPnlGetter,
            }
        },
        {
            headerName: "Dividend LTD", field: "dividend", 
            headerClass: 'align-left',
            cellClass: 'align-right',
            width:115,
            sort: null,
            cellRenderer: UnrealizedPnlCellRenderer,
            cellRendererParams: {
                valueFormatter: unRealizedPnlGetter,
            }
        },
        {
            headerName: "Realized DTD",field: 'realizedDTDPnl',
            //headerComponent: CustomHeader,
            valueFormatter:realizedPnlGetter,
            //headerComponentParams: { type: 'realized', headerName: "Realized Pnl"},
            headerClass: "custom-header-padding",
            cellClass: 'align-right',
            width:100,
            sort: null,
            cellRenderer: UnrealizedPnlCellRenderer,
            cellRendererParams: {
                valueFormatter: realizedPnlGetter
            },
        },
        {
            headerName: "Unrealized DTD",field: 'unRealizedDTDPnl',
            //headerComponent: CustomHeader,
            valueFormatter:unRealizedPnlGetter,
            //headerComponentParams: { type: 'unRealized', headerName: "Unrealized Pnl" },
            headerClass: "custom-header-padding",
            cellClass: 'align-right',
            width:120,
            sort: null,
            cellRenderer: UnrealizedPnlCellRenderer,
            cellRendererParams: {
                valueFormatter: unRealizedPnlGetter
            }
        },

        {
            headerName: "Realized MTD",field: 'realizedMTDPnl',
            //headerComponent: CustomHeader,
            valueFormatter:realizedPnlGetter,
            //headerComponentParams: { type: 'realized', headerName: "Realized Pnl"},
            headerClass: "custom-header-padding",
            cellClass: 'align-right',
            width:100,
            sort: null,
            cellRenderer: UnrealizedPnlCellRenderer,
            cellRendererParams: {
                valueFormatter: realizedPnlGetter
            },
        },
        {
            headerName: "Unrealized MTD",field: 'unRealizedMTDPnl',
            //headerComponent: CustomHeader,
            valueFormatter:unRealizedPnlGetter,
            //headerComponentParams: { type: 'unRealized', headerName: "Unrealized Pnl" },
            headerClass: "custom-header-padding",
            cellClass: 'align-right',
            width:120,
            sort: null,
            cellRenderer: UnrealizedPnlCellRenderer,
            cellRendererParams: {
                valueFormatter: unRealizedPnlGetter
            }
        },

        {
            headerName: "Realized YTD",field: 'realizedYTDPnl',
            //headerComponent: CustomHeader,
            valueFormatter:realizedPnlGetter,
            //headerComponentParams: { type: 'realized', headerName: "Realized Pnl"},
            headerClass: "custom-header-padding",
            cellClass: 'align-right',
            width:100,
            sort: null,
            cellRenderer: UnrealizedPnlCellRenderer,
            cellRendererParams: {
                valueFormatter: realizedPnlGetter
            },
        },
        {
            headerName: "Unrealized YTD",field: 'unRealizedYTDPnl',
            //headerComponent: CustomHeader,
            valueFormatter:unRealizedPnlGetter,
            //headerComponentParams: { type: 'unRealized', headerName: "Unrealized Pnl" },
            headerClass: "custom-header-padding",
            cellClass: 'align-right',
            width:120,
            sort: null,
            cellRenderer: UnrealizedPnlCellRenderer,
            cellRendererParams: {
                valueFormatter: unRealizedPnlGetter
            }
        }
    ]
};

const PnlTableOptionSlice = createSlice({
  name: 'pnlTableOption',
  initialState,
  reducers: {
    setTableOptionsData(state, action){
        state.columnOptions = action.payload
    },
    setFilterText(state, action){
        state.filterText = state.filterText =  /^[0-9]/.test(action.payload) 
        ? action.payload.substring(1) // Remove the first character if it's a number
        : action.payload.replace(/[^a-zA-Z0-9]/g, ""); //Allow only number and alphabets
    }
  }
});

export const { setTableOptionsData, setFilterText } = PnlTableOptionSlice.actions;
export default PnlTableOptionSlice.reducer;
