
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";
const ProtectedRoute = ({children}) => {
  const { isAuthenticated, error, isLoading } = useAuth0();

//   useEffect(() => {
//     if (isAuthenticated) {
//       getAccessTokenSilently()
//         .then((token) => console.log("Access Token:", token))
//         .catch((err) => console.error("Error getting token:", err));
//     }
//   }, [isAuthenticated, getAccessTokenSilently]);

  if (isLoading) {
    return <div></div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return isAuthenticated ? children : <Navigate to="/" />;
};

export default ProtectedRoute;
