/*eslint-disable*/
import React, { Component }  from 'react';
import Dashboard from "views/Dashboard.js";
import Positions from "views/Positions.js";
import Pnl from "views/Pnl.js";
import Trades from "views/Trades";
import Orders from "views/Orders.js";
import TradesFilter from 'components/TradesFilter/container/TradesFilter';
import PnlGrid from 'components/PnlGrid/presentation/PnlGrid';
import PositionsGrid from 'components/PositionsGrid/container/PositionsGrid';
import TradesGrid from 'components/TradesGrid/container/TradesGrid';
import { GiBreakingChain } from "react-icons/gi";
import {  CandlestickChart, ListOrdered, SearchCodeIcon, TrendingUp, Wallet} from 'lucide-react';
import {ImTree} from 'react-icons/im'
import GreeksAnalyzer from 'views/GreeksAnalyzer';
import OptionChainTemplate from 'components/OptionChain/OptionChainTemplate';
import ExternalTrades from 'components/ExternalTrades/ExternalTrades';

var routes = [
  {
    path: "/orders",
    name: "Orders ",
    icon: <Wallet/>,
    component: <Orders/>,
    layout: "/default",
  },
  {
    path: "/trades",
    name: "Trades",
    icon: <CandlestickChart/>,
    component: <Trades/>,
    layout: "/default",
  },
  {
    path: "/positions",
    name: "Positions",
    icon: <ListOrdered/>,
    component:<Positions/>,
    layout: "/default",
  },
  {
    path: "/pnl",
    name: "PnL",
    icon: <TrendingUp />,
    component: <Pnl/>,
    layout: "/default"
  },
  {
    path: "/greeks",
    name: "Greeks Analyzer",
    icon: <SearchCodeIcon />,
    component: <GreeksAnalyzer/>,
    layout: "/default"
  },
  {
    path: "/dashboard",
    name: "Zen Security Analyzer",
    icon: <ImTree/>,
    component: <Dashboard  />,
    layout: "/default",
  },
  {
    path: "/optionChain",
    name: "Option Chain",
    icon: "O",
    component: <OptionChainTemplate />,
    layout: "/default",
  },
  // {
  //   path: "/externalTrades",
  //   name: "External Trades",
  //   icon: "E",
  //   component: <ExternalTrades/>,
  //   layout: "/default",
  // }

];
export default routes;
