import { setBeta } from "./BetaSlice";
import config from "config";

const fetchBetaValues = async (clientId,accountIds,dispatch) => {
    const url = `${config.REACT_APP_DEV_URL}trade/portfolioBeta`;

    
   

    // Get today's date and format it
    const today = new Date().toISOString().split('T')[0]; // Format: YYYY-MM-DD
    const timestamp = `${today} 23:00:00`;

    const body = {
        "startTimestamp": timestamp,
        "endTimestamp": timestamp,
        "positionCompKeyFilter": {
            "clientId": clientId,
            "strategyIds": [1],
            "accountIds": accountIds
        }
    };

    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        dispatch(setBeta(data[0]?.portfolioBeta?.toFixed(3)))
    } catch (error) {
        console.error("Error fetching beta values:", error);
    }
};



export default fetchBetaValues