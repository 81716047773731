/*eslint-disable*/
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  clientId : null,
  strategyId : null,
  accountId : null,
  broker : null,
  clientList : null
};

const ExternalTradesSlice = createSlice({
  name: 'externalTrades',
  initialState,
  reducers: {
    setClientId(state, action){
        state.clientId = action.payload
    },
    setAccountId(state, action){
        state.accountId = action.payload
    },
    setBroker(state, action){
        state.broker = action.payload
    },
    setStrategyId(state, action){
        state.strategyId = action.payload
    },
    setClientList(state, action){
        state.clientList = action.payload
    }
  }
});

export const { setClientId, setAccountId, setBroker, setStrategyId, setClientList} = ExternalTradesSlice.actions;
export default ExternalTradesSlice.reducer;
