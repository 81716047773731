/*eslint-disable*/
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedClients : null,
  isDropdown : false
};

const DemoNavbarSlice = createSlice({
  name: 'navbar',
  initialState,
  reducers: {
    setSelectedClients(state, action){
        state.selectedClients = action.payload
    },
    toggleDropdown(state){
      state.isDropdown = !state.isDropdown
    }
  }
});

export const {setSelectedClients, toggleDropdown} = DemoNavbarSlice.actions;
export default DemoNavbarSlice.reducer;
