import React from 'react'

const UnrealizedPnlCellRenderer = (props) => {
    if(props.value>0){
        return <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">{props.valueFormatter({value:props.value}) }</span>
    }
    else if(props.value<0){
        return <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">{props.valueFormatter({value:props.value})}</span>
    }
    else if(props.value === 0)
        return <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">{props.valueFormatter({value:props.value})}</span>
    
    return null
}

export default UnrealizedPnlCellRenderer
